import React from 'react';

const Slide01 = ({i, item}) => {
    return (
        <div className="carousel-cell slide1" key={`slide-${i}`} style={{position:'relative', height:'100vh'}}>
            <div className="imgBG" style={{background:'url('+ item?.layout1?.image?.mediaItemUrl +')', backgroundPosition:'center center'}}>
                <div className="txt">
                    <div className="first">
                        <h3>{item?.layout1?.title}</h3>
                        {item?.layout1?.keyValuePairList && item?.layout1?.keyValuePairList.length>0 && (
                            <ul>
                                {item?.layout1?.keyValuePairList.map((itm, i) => <li key={`li-${i}`}><h5>{itm.key}</h5><h5>{itm.value}</h5></li>)}
                            </ul>
                        )}
                    </div>
                    <div className="second">
                        {item?.layout1?.logos && item?.layout1?.logos.length>0 && (
                            <div className="logos">
                                {item?.layout1?.logos.map((img, i) => <img key={`img-${i}`} src={img.mediaItemUrl}/>)}
                            </div>
                        )}
                        {item?.layout1?.quoteText && (
                            <div className="quoteText">
                                <div dangerouslySetInnerHTML={{__html: item?.layout1?.quoteText}}></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slide01;

import React, {useState} from 'react';
import chunkArray from '../../../utils/chunkArray';

const Slide03 = ({i, item}) => {

    let content = null;
    let arrayOfContent = [];
    
    if (item?.layout3?.secondBlock?.keyValuePairList.length>0)
    arrayOfContent = chunkArray(item?.layout3?.secondBlock?.keyValuePairList, 2);

    return (
        <div className="carousel-cell slide3" key={`slide-${i}`} style={{position:'relative', height:'100vh'}}>
            <div className="imgBG" style={{background:'url('+ item?.layout3?.image?.mediaItemUrl +')', backgroundPosition:'center center'}}>
                <div className="txt">
                    <div className="firstBlock">
                        <h3>{item?.layout3?.firstBlock?.title}</h3>
                        {item?.layout3?.firstBlock?.text && (
                            <p dangerouslySetInnerHTML={{__html: item?.layout3?.firstBlock?.text}}></p>
                        )}
                    </div>
                    <div className="secondBlock">
                            <h3>{item?.layout3?.secondBlock?.title}</h3>
                            {
                                arrayOfContent.length > 0 && (
                                    <div className="lists">
                                    {
                                        arrayOfContent.map((el, index) => {
                                            return (
                                                <ul key={index} className="list">
                                                {
                                                    el.map((itm, i) => {
                                                        return <li key={i}><h5>{itm.key}</h5><h5>{itm.value}</h5></li>
                                                    })
                                                }
                                                </ul>
                                            )
                                        })
                                    }
                                    </div>
                                )
                            }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slide03;

import React, {useState} from 'react';
import S_off from '../../images/sizes/size-43-off.jpg';
import S_on from '../../images/sizes/size-43-on.jpg';
import M_off from '../../images/sizes/size-55-off.jpg';
import M_on from '../../images/sizes/size-55-on.jpg';
import L_off from '../../images/sizes/size-65-off.jpg';
import L_on from '../../images/sizes/size-65-on.jpg';
import chunkArray from '../../../utils/chunkArray';

const Slide02 = ({i, item}) => {
    //console.log(item.layout2.firstBlock);

    const [selected, setSelected] = useState(1);

    let content = null;
    let arrayOfContent = [];
    let arrayOfContent2 = [];

    if (!selected) {
        content = item?.layout2?.firstBlock?.slideS?.keyValuePairList;
    }
    if (selected === 1) {
        content = item?.layout2?.firstBlock?.slideM?.keyValuePairList;
    }
    if (selected === 2) {
        content = item?.layout2?.firstBlock?.slideL?.keyValuePairList;
    }
    arrayOfContent = content && chunkArray(content, 2);
    
    if (item?.layout2?.secondBlock?.infos?.keyValuePairList?.length>0)
    arrayOfContent2 = chunkArray(item?.layout2?.secondBlock?.infos?.keyValuePairList, 2);



    return (
        <div className="carousel-cell slide2" key={`slide-${i}`} style={{position:'relative', height:'100vh'}}>
            <div className="imgBG" style={{background:'url('+ item?.layout2?.image?.mediaItemUrl +')', backgroundPosition:'center center'}}>
                <div className="txt">
                    {item?.layout2?.firstBlock?.title && (
                        <div className="firstBlock">
                            <h3>{item?.layout2?.firstBlock?.title}</h3>
                            <div className="imageSizes choose">
                                { !selected ?       <img src={S_on}/> : <img style={{zIndex:1}} onClick={() => setSelected(0)} src={S_off}/> }
                                { selected === 1 ?  <img src={M_on}/> : <img style={{zIndex:1}} onClick={() => setSelected(1)} src={M_off}/> }
                                { selected === 2 ?  <img src={L_on}/> : <img style={{zIndex:1}} onClick={() => setSelected(2)} src={L_off}/> }
                            </div>
                            {
                                arrayOfContent.length > 0 && (
                                    <div className="lists">
                                    {
                                        arrayOfContent.map((el, index) => {
                                            return (
                                                <ul key={index} className="list">
                                                {
                                                    el.map((itm, i) => {
                                                        return <li key={i}><h5>{itm.key}</h5><h5>{itm.value}</h5></li>
                                                    })
                                                }
                                                </ul>
                                            )
                                        })
                                    }
                                    </div>
                                )
                            }
                        </div>
                    )}
                    {item?.layout2?.secondBlock?.title && (
                        <div className="secondBlock">
                                <h3>{item?.layout2?.secondBlock?.title}</h3>
                                {
                                    arrayOfContent2.length > 0 && (
                                        <div className="lists">
                                        {
                                            arrayOfContent2.map((el, index) => {
                                                return (
                                                    <ul key={index} className="list">
                                                    {
                                                        el.map((itm, i) => {
                                                            return <li key={i}><h5>{itm.key}</h5><h5>{itm.value}</h5></li>
                                                        })
                                                    }
                                                    </ul>
                                                )
                                            })
                                        }
                                        </div>
                                    )
                                }
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Slide02;

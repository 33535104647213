import React, {useState, useRef} from 'react';
import Flickity from "react-flickity-component";
import "flickity/dist/flickity.min.css";
import Slide01 from './slide1';
import Slide02 from './slide2';
import Slide03 from './slide3';

const Carousel = ({info, noTitle}) => {

    const [active, setActive] = useState(0)
    const flRef = useRef();

    let notHiddenSlides = []
    notHiddenSlides = info.slides.length>0 && info.slides.filter((el) => !el.hide);
    
    return (
        <div className="Carousel InfoCarousel">
            {!noTitle && (
                <div className="txt">
                    <div dangerouslySetInnerHTML={{__html: info?.mainText}}></div>
                </div>
            )}
            <div className="img">
            {info?.slides.length > 0 && (
                <Flickity
                    flickityRef={() => {}}
                    ref={flRef}
                    static
                    className={"main-carousel"}
                    elementType={"div"}
                    options={{
                        contain: true,
                        // autoPlay:5000,
                        autoPlay: false, 
                        prevNextButtons: true,
                        contain: true,
                        wrapAround:true,
                        arrowShape : "M69.5719282,7.87947368 C69.7298915,8.07090526 69.8725821,8.24023158 70,8.39452632 L31.4111422,45.9734737 L70,83.5789474 C69.8725821,83.7292632 69.7298915,83.9251158 69.5719282,84.1169895 C68.7812391,85.0454105 66.4475717,88 64.6999393,88 C63.9363047,87.9659579 63.2922334,87.6693053 62.6595076,87.0640632 L23.0561067,48.4810947 C21.6479644,47.1088 21.6479644,44.8836842 23.0561067,43.5153684 L62.6595076,4.9324 C63.2922334,4.32317895 63.9363047,4.02652632 64.6999393,4 C66.4475717,4 68.7812391,6.94707368 69.5719282,7.87947368 Z",
                        // pauseAutoPlayOnHover:false,
                        on: {
                            ready: function() {
                                setActive(0);
                            },
                            change: function( index ) {
                                // flRef && flRef.current && flRef.current.flkty.player.play()
                                setTimeout(() => {
                                    setActive(index);
                                }, 1);
                            },
                        }
                    }}
                >

                    {notHiddenSlides.length>0 && notHiddenSlides.map((item, i) => {
                        if (item.chooseLayout === '1') {
                            return <Slide01 key={i} item={item} /> 
                        }
                        if (item.chooseLayout === '2') {
                            return <Slide02 key={i} item={item} /> 
                        }
                        if (item.chooseLayout === '3') {
                            return <Slide03 key={i} item={item} />
                        }
                    })}
                </Flickity>
            )}
        </div>
    </div>
    )
}

export default Carousel;


import React, {useState, useEffect} from 'react';
import InfoCarousel from '../../../components/editorial/InfoCarousel';
import {graphql} from 'gatsby';
import Layout from '../../../UI/Layout';
import useLS from '../../../hooks/useLS';
import HeaderData from '../../../../queries/data/header/header-glass';


const TechSpech = (props) => {

  const {data, uri} = props;

    useLS();
    const dataHeader = HeaderData.data.wp.tv.dataHeader;

    const homeData = data.wp.pages.nodes.filter((itm) => itm.databaseId === 2);
    const techSpec = homeData[0].sky.techSpec;

    let content = (
        <>
            <Layout uri={uri} dataHeader={dataHeader}>
              <section className="Editorial">
                  <div className="afterHeader">
                      <InfoCarousel withLoader noTitle info={techSpec}/>
                  </div>
              </section>
            </Layout>
        </>
    )

    return content
}

export default TechSpech;


export const query = graphql`
  {
    wp {
      pages {
        nodes {
          title
          databaseId
          sky {
            techSpec {
              slides {
                hide
                chooseLayout
                layout3 {
                  firstBlock {
                    text
                    title
                  }
                  secondBlock {
                    title
                    keyValuePairList {
                      key
                      value
                    }
                  }
                  image {
                    mediaItemUrl
                  }
                }
                layout2 {
                  firstBlock {
                    slideL {
                      keyValuePairList {
                        key
                        value
                      }
                    }
                    title
                    slideM {
                      keyValuePairList {
                        key
                        value
                      }
                    }
                    slideS {
                      keyValuePairList {
                        key
                        value
                      }
                    }
                  }
                  secondBlock {
                    title
                    infos {
                      keyValuePairList {
                        key
                        value
                      }
                    }
                  }
                  image {
                    mediaItemUrl
                  }
                }
                layout1 {
                  image {
                    mediaItemUrl
                  }
                  keyValuePairList {
                    key
                    value
                  }
                  logos {
                    mediaItemUrl
                  }
                  title
                  quoteText
                }
              }
            }
          }
        }
      }
    }
  }
`;